<template>
    <div class="uk-width-1-1">
            <div class="uk-card-default uk-card-small uk-card-hover">
                <div class="uk-card-header">
                    <div class="uk-grid uk-grid-small">
                        <div class="uk-width-expand"> 
                            <div class="uk-inline uk-width-1-1">
                                <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: search"></span>
                                <input class="uk-input uk-width-1-1 search-fld" type="text" v-model="val" :placeholder="localizeFilter('Search')" @input="filter(val)">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body uk-margin-medium-bottom uk-padding-remove-top">
                    <div class="form-container uk-overflow-auto">
                        <table class="uk-table uk-table-hover uk-table-middle uk-table-divider" style="background-color: white;">
                            <thead>
                                <tr>
                                    <th class="uk-table-shrink">#</th>
                                    <th class="">{{"Login" | localize}}</th>
                                    <th class="">{{"Event" | localize}}</th>
                                    <th class="">{{"Date" | localize}}</th>
                                    <th class="">{{"Manage" | localize}}</th>
                                </tr>
                            </thead>
                            <tbody v-for="(event, cnt) in events">
                                <tr>
                                    <td>{{cnt + 1}}</td>
                                    <td>{{event.user || '-'}}</td>
                                    <td>{{event.event || '-'}}</td>
                                    <td>{{event.date || '-'}}</td>
                                    <td>
                                        <ul class="uk-iconnav">
                                            <li><a uk-icon="icon: trash" :uk-tooltip="'title:' + localizeFilter('Delete') +'; pos: bottom'" @click="deleteEvent(event.id, cnt)"></a></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
</template>


<script>
import localizeFilter from '@/filter/localize.filter'
import getJournal from '@/config/GetJournal.dashboard'
import deleteEvent from '@/config/DeleteJournal.dashboard'
export default {
    data: () => ({
        events: '',
        allevents: '',
        val: ''
    }),
    methods:{
        getJournal, deleteEvent, localizeFilter,
        filter(val){
            if(val === ''){
                this.events = this.allevents;
            }else{
                this.events = this.allevents;
                const filterValue = (name) => {
                    return this.events.filter(data => {
                        return data.event.toLowerCase().indexOf(name.toLowerCase()) > -1;
                    });
                }
                this.events = filterValue(val)
            }
        }
    },
    beforeMount(){
        this.getJournal()
    }
}
</script>