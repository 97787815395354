import localizeFilter from '@/filter/localize.filter'
export default function deleteCatalityc(id, cnt){
    if(id != ''){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 23,
                    id: id
                }
            })
            
            .then(response => {
                if(response.data != -10){
                    this.$data.events.splice(cnt, 1);
                    this.$data.allevents = this.$data.events
                    UIkit.notification({message: localizeFilter('EventDeleted'), status:'danger'})
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}